.randevu-form {
  .form {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 30px 40px;
    min-width: 470px;


    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: rgba(88, 88, 88, 0.9);
      margin-bottom:8px;
    }
    .p-button-danger{
      cursor: pointer;
      &:hover{
        opacity:0.8
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .randevu-form {
    .form {
      min-width: 300px;
    }
  }
}

@media screen and (max-width:768px){
      .randevu-form{
        width: 100%;
        .form{
          min-width: 100%;
          border-radius:0;
          box-shadow:none;
          padding:30px 0 !important;
        }
      }
}
