
.page-title2{
  height: 50px!important;
  width: 100%;
  padding:40px 0 75px 80px;
  // background-image:url('../../../assets/images/opacity.svg'), url('../../../assets/images/doctors1.png');
  background-repeat: no-repeat;
  background-size: cover;
  .mob{
    display:none;
  }
  .breadcrumbs{
   display:flex;
   align-items: center;
   gap: 5px;
   margin-bottom: 75px;
   .link{
     font-size: 18px;
     line-height: 140%;
     color: #555555;
     text-decoration:none
   }
   .main{
     color: #191919;
     font-weight:600;
   }
 }
  .title{
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #fff;
    span{
      color: #0461a6;
    }
  }
}

@media screen and (max-width: 768px){
  .page-title{
    background-image:none;
    background-color: #EFECEB;
    height: 140px;
    padding:32px 0 42px 32px;
    border-bottom: 6px solid #F7F7F7;
    .breadcrumbs{
      margin-bottom: 22px;
      .link{
        font-size: 14px;
      }
    }
    .title{
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      span{
        color: #0461a6;
      }
    }
    .mob{
      display:block;
    }
  }
}

