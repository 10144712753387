@import "../../../assets/scss/vaiables";

.services {
  padding: 0 64px;

  .top {
    h3 {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #575757;
    }

    .content {
      display: flex;
      justify-content:center;
      align-items: center;

      .left {
        font-weight: 700;
        font-size: 48px;
        max-width: 445px;
        line-height: 72px;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.04em;
        color: #252525;
      }

      .right {
        max-width: 600px;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.02em;
        color: rgba(88, 88, 88, 0.9);
        opacity: 0.6;
      }
    }
  }

  .bottom {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    align-items: center;
  }
  .mob-bottom{
    display:none;
  }
}
@media (max-width: 1200px){
  .services{
    .top{
      .content{
        padding: 10px 0;
        .left{
          font-size:35px;
          line-height:60px;
        }
        .right{
          max-width:300px
        }
      }

    }
    .bottom{
      align-items:center;
      .cards{
        justify-content:center;
        gap: 30px;
      }
    }
  }
}
@media screen and (max-width:768px){
  .services{
    padding:0 44px;
    margin-top:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top{
      h3{
        font-size:10px;
        margin-bottom:8px;
      }
      .content{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        justify-content: center;
        gap: 24px;
        .left{
          font-size:24px;
          max-width:400px;
          font-weight: 600;
          line-height:150%;
        }
        .right {
          min-width: 280px;
          font-size: 16px;
          line-height: 28px;
        }
      }

    }
    .bottom{
      display:none;
    }
    .mob-bottom{
      display:flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      margin:40px 0 36px;
      gap: 24px;
      .all{
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:500;
        color: #0461a6;
        gap: 10px;
      }
    }
  }
}