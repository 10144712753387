.gallery-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-galleria-mask {
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensures exactly 3 items per row */
  gap: 16px;
  padding: 16px;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-item:hover {
  transform: translateY(-5px);
}

.grid-item img {
  width: 100%;
  height: 200px; /* Set a fixed height for images */
  object-fit: cover; /* Ensures images cover the container while maintaining aspect ratio */
}

.gallery-item-caption {
  padding: 8px;
  text-align: center;
}

.modal {
  z-index: 9999; /* Diğer içeriklerin üstünde olmasını sağlar */
}


.close-button {
  z-index: 10000; /* Diğer içeriklerin üstünde olmasını sağlar */
}

.gallery-item-caption h5 {
  margin: 8px 0;
  font-size: 1.1em;
}

.gallery-item-caption p {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}

